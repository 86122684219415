import React, { useEffect, useState } from "react";
import Button from "../../../components/Button";
import CardLayout from "../../../components/CardLayout";
import ConverterBox from "../../../components/ConverterBox";
import Input from "../../../components/Input";
import ScreenShot from "../../../components/ScreensShot/ScreenShot";
import { Notification } from "../../../components/ToastNotification";
import { withdrawAmountValidate } from "../../../components/Validation/vaildate";
import { useGetLoginUserQuery } from "../../../Services/userApi";
import {
  useAddWithdrawFundsMutation,
  useGetAllWalletQuery,
} from "../../../Services/walletApi";
// import { useAddWithdrawFundsMutation } from "../../../Services/withdrawApi";

const WithdrawPage = () => {
  const { data: userData } = useGetLoginUserQuery();
  const [showTrxField, setShowTrxField] = useState(false);
  const [formErrors, setFormErrors] = useState({}); // form errors
  const [data, setData] = useState({
    amount: "",
    trx_address: userData?.data?.wallet_address,
    trx_password: "",
  });

  // error
  useEffect(() => {
    setFormErrors(withdrawAmountValidate(data));
  }, [data]);
  const [addWithdraw, { error, data: withdrawData, isLoading }] =
    useAddWithdrawFundsMutation();
  useEffect(() => {
    if (withdrawData?.message) {
      Notification(withdrawData?.message, "success");
      setData({
        amount: "",
        trx_password: "",
      });
      setShowTrxField(false);
    } else {
      Notification(error?.data?.message, "error");
    }
  }, [error, withdrawData]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (Object.keys(formErrors).length > 0) {
      Notification("All conditions and fields are required", "error");
      setShowTrxField(false);
    } else {
      if (!data.trx_password) {
        setShowTrxField(true);
        Notification("Transaction password is required", "error");
      } else {
        setShowTrxField(true);
        await addWithdraw(data);
      }
    }
  };
  const { data: allWalletInfo } = useGetAllWalletQuery();
  const trx = parseFloat(allWalletInfo?.total_income);
  const trx_amount = (trx * 14.16)?.toFixed(3);
  return (
    <div className="tronFlow_topupaccount_page_wrapper">
      <ScreenShot width={600} height={350} pageName={"withdraw funds"} />
      <CardLayout
        style={{ backgroundColor: "#fff" }}
        className="tronFlow_topupaccount_form_card"
      >
        <div className="tronFlow_section_title">
          <h2>Withdraw</h2>
          <div className="tronFlow_section_title_right_side">
            <div className="tronFlow_section_title_balance tronFlow_section_Trx_balance">
              <p>TRX: {trx_amount}</p>
            </div>
            <div className="tronFlow_section_title_balance">
              <p>
                USD:{" "}
                {`$${
                  allWalletInfo?.total_income
                    ? parseFloat(allWalletInfo?.total_income).toFixed(3)
                    : "0"
                }`}
              </p>
            </div>
          </div>
        </div>
        <ConverterBox />
        <div className="tronFlow_topupaccount_page_content">
          <form onSubmit={handleSubmit}>
            <div className="form_group">
              <Input
                label="Amount"
                type="number"
                name="amount"
                value={data.amount}
                placeholder="Enter your amount"
                onChange={(e) => setData({ ...data, amount: e.target.value })}
                className="input_field"
                inputGroupClass="left amount_field"
                isRequired={true}
              />
              <Input
                label="TRX Address"
                type="text"
                name="trx_address"
                value={data.trx_address}
                placeholder="Enter your amount"
                onChange={(e) =>
                  setData({ ...data, trx_address: e.target.value })
                }
                className="input_field"
                inputGroupClass="right"
                isRequired={true}
                disabled={true}
                style={{ cursor: "no-drop" }}
              />
            </div>
            {showTrxField && (
              <div className="form_group">
                <Input
                  label="Transaction Password"
                  type="text"
                  name="trx_password"
                  value={data.trx_password}
                  placeholder="Enter your transaction password"
                  onChange={(e) =>
                    setData({ ...data, trx_password: e.target.value })
                  }
                  className="input_field"
                  inputGroupClass="left"
                  isRequired={true}
                />
                <Input
                  label=""
                  type="text"
                  name=""
                  className="input_field"
                  inputGroupClass="right"
                  disabled={true}
                  style={{ display: "none" }}
                />
              </div>
            )}
            <Button type="submit" className="submit_btn" disabled={isLoading}>
              {isLoading ? "Loading..." : "Withdraw"}
            </Button>
          </form>
        </div>
      </CardLayout>
    </div>
  );
};

export default WithdrawPage;
