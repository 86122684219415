import React from "react";
import ScreenShot from "../../../components/ScreensShot/ScreenShot";
import SectionCommonTable from "../../../components/SectionCommonTable";
import FundTransHistoryTable from "./table/FundTransferHistoryTable";

const FundTransferHistoryPage = () => {
  return (
    <>
      <ScreenShot width={600} height={440} pageName={"transfer-funds-status"} />
      <SectionCommonTable
        wrapperClassName="fundtransferhistory_table"
        cardStyle={{ backgroundColor: "#fff" }}
        sectionTableTitle="Fund Transfer History"
        table={<FundTransHistoryTable />}
      />
    </>
  );
};

export default FundTransferHistoryPage;
