import React from "react";
import SectionCommonTable from "../../../components/SectionCommonTable";
import TopupHistoryTable from "./table/topupHistoryTable";
import ScreenShot from "../../../components/ScreensShot/ScreenShot";
const TopupHistoryPage = () => {
  // const [activePlette, setActivePlette] = useState(false);
  return (
    <>
      <ScreenShot width={600} height={410} pageName={"topUp-history-status"} />
      <SectionCommonTable
        wrapperClassName="topuphistory_table"
        cardStyle={{ backgroundColor: "#fff" }}
        sectionTableTitle="Topup History"
        table={<TopupHistoryTable />}
      />
    </>
  );
};

export default TopupHistoryPage;
