import React from "react";
import { useState } from "react";
import ScreenShot from "../../../components/ScreensShot/ScreenShot";
import SectionCommonTable from "../../../components/SectionCommonTable";
import { useCanceledDepositeHistoryQuery } from "../../../Services/depositeApi";
import RejectDepositTable from "./table/rejectDepositTable";

const RejectedTransaction = () => {
  const { data } = useCanceledDepositeHistoryQuery();
  const [filterData, setFilterData] = useState([]);
  return (
    <>
      <ScreenShot
        width={600}
        height={400}
        pageName={"all-rejected-deposit-status"}
      />
      <SectionCommonTable
        wrapperClassName="allmember_table"
        cardStyle={{ backgroundColor: "#fff" }}
        sectionTableTitle={`Reject Deposit History (${data?.length})`}
        data={data}
        setFilterData={setFilterData}
        table={
          <RejectDepositTable
            data={filterData.length > 0 ? filterData : data}
          />
        }
      />
    </>
  );
};

export default RejectedTransaction;
