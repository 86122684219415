import React from "react";
import HomeCard from "../../UserPages/HomePage/Home.Card";
import totalUserIcon from "../../../assets/dashboardIcon/team.png";
import blockUserIcon from "../../../assets/dashboardIcon/block-user.png";
import TotalWithdrawIcon from "../../../assets/dashboardIcon/withdraw-money.png";
import activeUserIcon from "../../../assets/dashboardIcon/active-user.png";
import pendingWithdrawIcon from "../../../assets/dashboardIcon/pending-payment.png";
import totalInvestmentIcon from "../../../assets/dashboardIcon/invest.png";
import depositIcon from "../../../assets/dashboardIcon/reward.png";
import { useGetAllUserQuery } from "../../../Services/userApi";
import ScreenShot from "../../../components/ScreensShot/ScreenShot";

const AdminHome = () => {
  const { data } = useGetAllUserQuery();
  return (
    <div className="tronFlow_adminHome_wrapper">
      <ScreenShot width={600} height={410} pageName={"admin_dashboard"} />
      <div className="tronFlow_section_title">
        <h2>Dashboard</h2>
      </div>
      <div className="tronFlow_dash_content_item">
        <div className="tronFlow_dash_content card_row">
          <HomeCard
            cardName="Total User"
            cardValue={data?.allUser ? data?.allUser : "0"}
            icon={totalUserIcon}
            linkText="view details"
            bgColor="#38cab3"
          />
          <HomeCard
            cardName="Active User"
            cardValue={data?.activeUser ? data?.activeUser : "0"}
            icon={activeUserIcon}
            linkText="view details"
            bgColor="#ffbd5a"
          />
          <HomeCard
            cardName="Blocked User"
            cardValue={data?.blockUser ? data?.blockUser : "0"}
            icon={blockUserIcon}
            linkText="view details"
            bgColor="#f74f75"
          />
          <HomeCard
            cardName="Pending Withdraw"
            cardValue={`$${
              data?.pendingWithdraws
                ? parseInt(data?.pendingWithdraws)?.toFixed(3)
                : "0"
            }`}
            icon={pendingWithdrawIcon}
            linkText="view details"
            bgColor="#4ec2f0"
          />
          <HomeCard
            cardName="Total Withdraw"
            cardValue={`$${
              data?.allWithdraw ? parseInt(data?.allWithdraw)?.toFixed(3) : "0"
            }`}
            icon={TotalWithdrawIcon}
            linkText="view details"
            bgColor="#39bd57"
          />
          <HomeCard
            cardName="Total Investment"
            cardValue={`$${
              data?.totalInvest ? parseInt(data?.totalInvest)?.toFixed(3) : "0"
            }`}
            icon={totalInvestmentIcon}
            linkText="view details"
            bgColor="#FF87B2"
          />
          <HomeCard
            cardName="Total Deposit"
            cardValue={`$${
              data?.totalDeposite
                ? parseInt(data?.totalDeposite)?.toFixed(3)
                : "0"
            }`}
            icon={depositIcon}
            linkText="view details"
            bgColor="#18a7b5"
          />
        </div>
      </div>
    </div>
  );
};

export default AdminHome;
