import bplan from "../../assets/frontpage/icon/bplan.png";
import community from "../../assets/frontpage/icon/community.png";
import vision from "../../assets/frontpage/icon/vision.png";
import security from "../../assets/frontpage/icon/encrypted.png";
export const BusinessCard = [
  {
    id: "78df8sf8",
    img: vision,
    title: "The Vision",
    description:
      "Our vision is to create a successful community that makes it easy to earn cryptocurrency, build a passive income and create incredible wealth.",
  },
  {
    id: "78d5s5f",
    img: bplan,
    title: "The Business Plan",
    description:
      "Our business plan and rewards program is lucrative, realistic and fair. With 8 ways to earn and uncapped income potential, Tron Flow is your key to financial freedom.",
  },
  {
    id: "78df8sf8",
    img: community,
    title: "Community Support",
    description:
      "All members will receive ongoing support, empowering them to make the most of the programme and teaching others how to earn cryptocurrency.",
  },
  {
    id: "785df5s5",
    img: security,
    title: "Security",
    description:
      "All members will receive ongoing support, empowering them to make the most of the programme and teaching others how to earn cryptocurrency.",
  },
];
