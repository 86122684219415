import React from "react";
import DataTable from "../../../../components/DataTable";

const columns = [
  { id: "sn", label: "S.N", minWidth: 20 },
  { id: "userid", label: "User ID", minWidth: 100 },
  {
    id: "date",
    label: "Date",
    minWidth: 120,
  },
  {
    id: "amount",
    label: "Amount",
    minWidth: 80,
  },
  {
    id: "transaction_id",
    label: "Transaction ID",
    minWidth: 100,
  },
];

const LevelTable = ({ showDetails,data }) => {
  function createData(sn, userid, date, amount, transaction_id) {
    return { sn, userid, date, amount, transaction_id };
  }

  // console.log(data)
  const rows = data?.map((d, index) =>
    createData(
      index + 1,
      d?.sponsor_id,
      new Date(d?.date).toDateString(),
      "$"+ parseInt(d?.amount)?.toFixed(3),
      <span
        onClick={() => showDetails(d)}
        style={{
          userSelect: "none",
          cursor: "pointer",
          textDecoration: "underline",
        }}
      >
        {d?.transaction_id}
      </span>
    )
  );
  return (
    <DataTable
      columns={columns}
      rows={rows}
      perPageShow={6}
      tableHeight={440}
      className="common_table"
    />
  );
};

export default LevelTable;
