import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { env } from "../env";
import { getLocalStorage } from "../utils/function/localStorage";

export const topupApi = createApi({
  reducerPath: "topupApi",
  baseQuery: fetchBaseQuery({
    baseUrl: env.BASE_URL,
    // mode: 'cors',
    prepareHeaders: (headers) => {
      headers.set("authorization", getLocalStorage("tron_token"));
      return headers;
    },
  }),
  tagTypes: ["userTopup", "adminUser"], // automatic-data fetching
  endpoints: (builder) => ({
    // user
    // topupHistory: builder.query({
    //   query: () => `/secure/api/topup_history`,
    //   providesTags: ["userTopup"], // automatic-data fetching
    // }),
    // topupApiPackage: builder.mutation({
    //   query: (body) => ({
    //     url: "/secure/api/make_topup",
    //     method: "POST",
    //     body,
    //   }),
    //   invalidatesTags: ["userTopup"], // automatic-data fetching
    // }),
    addTopUp: builder.mutation({
      query: (body) => ({
        url: "/private/api/make_topup",
        method: "POST",
        body,
      }),
      invalidatesTags: ["adminUser"],
    }),
    // createROI: builder.mutation({
    //   query: (body) => ({
    //     url: "/secure/api/create_investment",
    //     method: "POST",
    //     body,
    //   }),
    //   invalidatesTags: ["userTopup"], // automatic-data fetching
    // }),
    //admin
    createROIAdmin: builder.mutation({
      query: (body) => ({
        url: "/private/api/create_investment",
        method: "POST",
        body,
      }),
      invalidatesTags: ["adminUser"],
    }),
  }),
});

export const {
  // useTopupHistoryQuery,
  // useTopupApiPackageMutation,
  // useCreateROIMutation,
  useAddTopUpMutation,
  useCreateROIAdminMutation,
} = topupApi;
