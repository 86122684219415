import React from "react";
import DataTable from "../../../../components/DataTable";

const columns = [
  { id: "sr", label: "Sr.", minWidth: 20 },
  { id: "userId", label: "User ID", minWidth: 100 },
  {
    id: "date",
    label: "Date",
    minWidth: 120,
  },
  {
    id: "request_amount",
    label: "Request Amount",
    minWidth: 110,
  },
  {
    id: "trx_amount",
    label: "Amount (Trx)",
    minWidth: 140,
  },
  {
    id: "transaction_id",
    label: "Transaction ID",
    minWidth: 110,
  },
  {
    id: "action",
    label: "Action",
    minWidth: 100,
  },
];

const AllWithdrawTable = ({ data, showDetails, statusChange }) => {
  function createData(
    sr,
    userId,
    date,
    request_amount,
    trx_amount,
    transaction_id,
    action
  ) {
    return {
      sr,
      userId,
      date,
      request_amount,
      trx_amount,
      transaction_id,
      action,
    };
  }

  const rows = data?.map((d, index) =>
    createData(
      index + 1,
      d?.user_id,
      new Date(d?.date).toDateString(),
      "$" + parseInt(d?.amount)?.toFixed(3),
      parseInt(d?.amount * 17)?.toFixed(3) , 
      <span
        onClick={() => showDetails(d)}
        style={{
          userSelect: "none",
          cursor: "pointer",
          textDecoration: "underline",
        }}
      >
        {d?.transaction_id}
      </span>,
      <span>
        <select
          name="status"
          style={{
            border: "none",
            outline: "none",
            padding: "5px 8px",
            borderRadius: "5px",
            textTransform: "capitalize",
            backgroundColor:
              d.status === "pending"
                ? "rgba(255,189,90,.2)"
                : d.status === "success"
                ? "rgba(28,213,174,.2)"
                : "rgba(247,79,117,.2)",
            color:
              d.status === "pending"
                ? "#ffc107"
                : d.status === "success"
                ? "#38cab3"
                : "#f74f75",
          }}
          value={d.status}
          onChange={(e) => statusChange(e.target.value, d?.transaction_id)}
        >
          <option value="pending">pending</option>
          <option value="success">success</option>
          <option value="reject">reject</option>
        </select>
      </span>
    )
  );
  // console.log(data);
  return (
    <DataTable
      columns={columns}
      rows={rows}
      perPageShow={6}
      tableHeight={440}
      className="common_table"
    />
  );
};

export default AllWithdrawTable;
