import React, { useEffect, useState } from "react";
import Button from "../../../components/Button";
import CardLayout from "../../../components/CardLayout";
import Input from "../../../components/Input";
import ScreenShot from "../../../components/ScreensShot/ScreenShot";
import { Notification } from "../../../components/ToastNotification";
import { updateTxrAddressValidate } from "../../../components/Validation/vaildate";
import {
  useEditTrxAddressMutation,
  useGetLoginUserQuery,
} from "../../../Services/userApi";

const UpdateWallet = () => {
  const { data: userData } = useGetLoginUserQuery();
  const [formErrors, setFormErrors] = useState({}); // form errors
  const [data, setData] = useState({
    trx_address: userData?.data?.wallet_address,
  });

  // trx address update
  const [editTrxWallet, { error, data: trxData, isLoading }] =
    useEditTrxAddressMutation();

  useEffect(() => {
    if (trxData?.message) {
      Notification(trxData?.message, "success");
    } else {
      Notification(error?.data?.message, "error");
    }
  }, [error, trxData]);
  useEffect(() => {
    setFormErrors(updateTxrAddressValidate(data));
  }, [data]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (Object.keys(formErrors).length > 0) {
      Notification("Space is not allow in TRX Address", "error");
    } else {
      // console.log(data);
      await editTrxWallet(data);
    }
  };
  return (
    <div className="tronFlow_updatewallet_page_wrapper">
      <ScreenShot width={600} height={410} pageName={"Update-wallet"} />
      <CardLayout
        style={{ backgroundColor: "#fff" }}
        className="tronFlow_updatewallet_card"
      >
        <div className="tronFlow_updatewallet_title">
          <h2>Add or Update TRX Address</h2>
        </div>
        <div className="tronFlow_updatewallet_field">
          <form onSubmit={handleSubmit}>
            <div className="form_group">
              <Input
                label="TRX Address"
                type="text"
                value={data.trx_address}
                name="trx_address"
                onChange={(e) =>
                  setData({ ...data, trx_address: e.target.value })
                }
                placeholder="Enter trx wallet address"
              />
            </div>
            <Button
              type="submit"
              className="updatewallet_btn"
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "update"}
            </Button>
          </form>
        </div>
      </CardLayout>
    </div>
  );
};

export default UpdateWallet;
