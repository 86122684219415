import React from "react";
import SectionCommonTable from "../../../components/SectionCommonTable";
import WithdrawHistoryTable from "./table/withdrawHistoryTable";
import ScreenShot from "../../../components/ScreensShot/ScreenShot";

const WithdrawHistoryPage = () => {
  return (
    <>
    <ScreenShot width={600} height={420} pageName={"withdraw history status"}/>
      <SectionCommonTable
        wrapperClassName="withdrawrhistory_table"
        cardStyle={{ backgroundColor: "#fff" }}
        sectionTableTitle="Withdraw History"
        table={<WithdrawHistoryTable />}
      />
    </>
  );
};

export default WithdrawHistoryPage;
