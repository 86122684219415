import React from "react";
// import { MdAttachMoney } from "react-icons/md";
import CardLayout from "../../../components/CardLayout";
import SectionCommonTable from "../../../components/SectionCommonTable";
import UserIncomeCard from "../../../components/UserIncomeCard/UserIncomeCard";
import RewardIncomeTable from "./Table/rewardIncomeTable";
import RewardIncomeIcon from "../../../assets/dashboardIcon/reward.png";
import { useGetAllWalletQuery } from "../../../Services/walletApi";
import { useRewardIncomeDataUserQuery } from "../../../Services/earningApi";
import RewardIncomeBarchart from "./BarChart/RewardIncomeBarchart";

import ScreenShot from "../../../components/ScreensShot/ScreenShot";
const RewardIncomePage = () => {
  const { data } = useGetAllWalletQuery();
  const { data: rewardIncome } = useRewardIncomeDataUserQuery();
  const amount = parseFloat(data?.reward_income).toFixed(3);
  // const [activePlette, setActivePlette] = useState(false);
  return (
    <>
      <div className="UserEarning_wallet_page_wrapper">
        <div className="UserEarning_dash_content card_row">
          <ScreenShot
            width={600}
            height={410}
            pageName={"Reward-Income-status"}
          />
          <UserIncomeCard
            cardName="Reward Income"
            cardValue={`$${amount ? amount : "0"}`}
            icon={RewardIncomeIcon}
            bgColor="#f74f75"
            linkText="view details"
          />
          <CardLayout
            style={{ backgroundColor: "#fff", overflow: "hidden" }}
            className="tronFlow_dashboard_chart"
          >
            <RewardIncomeBarchart data={rewardIncome} />
          </CardLayout>
        </div>
        <SectionCommonTable
          wrapperClassName="rewardIncome_table"
          cardStyle={{ backgroundColor: "#fff" }}
          sectionTableTitle="Reward Income"
          table={<RewardIncomeTable data={rewardIncome} />}
        />
      </div>
    </>
  );
};

export default RewardIncomePage;
