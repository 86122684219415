import React, { useEffect } from "react";
import avatar from "../../../assets/avatar.png";
import CardLayout from "../../../components/CardLayout";
import Input from "../../../components/Input";
import InputWithClipBoard from "../../../components/Input/InputWithClipBoard";
import ScreenShot from "../../../components/ScreensShot/ScreenShot";
import { Notification } from "../../../components/ToastNotification";
import {
  useEditImageMutation,
  useGetLoginUserQuery,
} from "../../../Services/userApi";

const MyProfile = () => {
  const { data } = useGetLoginUserQuery();
  // edit profile
  const [uploadImage, { error, data: user, isLoading }] =
    useEditImageMutation();
  useEffect(() => {
    if (user?.message) {
      Notification(user?.message, "success");
    } else {
      Notification(error?.data?.message, "error");
    }
  }, [error, user]);
  const handleChange = async (e) => {
    let formData = new FormData();
    formData.append("image", e.target.files[0]);
    await uploadImage(formData);
  };

  return (
    <div className="tronFlow_my_profile_page_wrapper">
      <ScreenShot width={600} height={410} pageName={"My-profile"} />
      <CardLayout
        style={{ backgroundColor: "#fff" }}
        className="tronFlow_my_profile_card"
      >
        <div className="tronFlow_section_title">
          <h2>profile</h2>
        </div>
        <div className="tronFlow_profile_head">
          <div className="tronFlow_photo_content">
            <div className="tronFlow_cover_photo"></div>
          </div>
          <div className="tronFlow_profile_short_info">
            <div className="tronFlow_profile_photo">
              <img
                src={data?.data?.avatar ? data?.data?.avatar : avatar}
                width="100%"
                alt="img"
              />

              {isLoading && "Uploading..."}
              <form encType="multipart/form-data">
                <div className="form-check form-check-label">
                  <label htmlFor="img" className="form-check-label">
                    <Input
                      type="file"
                      name="image"
                      className="form-check-label"
                      onChange={handleChange}
                    />
                  </label>
                </div>
              </form>
            </div>
            <div className="tronFlow_profile_info">
              <div className="tronFlow_profile_name">
                <h2>{data?.data?.name}</h2>
                <p>Role: {data?.data?.role}</p>
              </div>
              <div className="tronFlow_profile_email">
                <h2>{data?.data?.email}</h2>
                <p>Email</p>
              </div>
            </div>
          </div>
        </div>
        <div className="tronFlow_profile_bottom">
          <div className="tronFlow_profile_title">
            <h2>Profile Info details</h2>
          </div>
          <div className="tronFlow_profile_field">
            <div className="form_group">
              <Input
                label="User ID"
                type="text"
                value={data?.data?.user_id}
                disabled={true}
                inputGroupClass="left"
              />
              <Input
                label="Name"
                type="text"
                value={data?.data?.name}
                disabled={true}
                inputGroupClass="right"
              />
            </div>
            <div className="form_group">
              <Input
                label="Sponsor ID"
                type="text"
                value={data?.data?.sponsor_id}
                disabled={true}
                inputGroupClass="left"
              />
              <Input
                label="Sponoser Name"
                type="text"
                value={data?.data?.sponsor_name}
                disabled={true}
                inputGroupClass="right"
              />
            </div>
            <div className="form_group">
              <Input
                label="Email"
                type="text"
                value={data?.data?.email}
                disabled={true}
                inputGroupClass="left"
              />
              <Input
                label="Phone No."
                type="text"
                value={data?.data?.mobile}
                disabled={true}
                inputGroupClass="right"
              />
            </div>
            <div className="form_group">
              <Input
                label="Gender"
                type="text"
                value={data?.data?.gender}
                disabled={true}
                inputGroupClass="left"
              />
              <Input
                label="Country"
                type="text"
                value={data?.data?.country}
                disabled={true}
                inputGroupClass="right"
              />
            </div>
            <div className="form_group">
              <InputWithClipBoard
                label="TRX Address"
                type="text"
                value={data?.data?.wallet_address}
                disabled={true}
                inputGroupClass="left"
                copyToClipboard={true}
                visibility="visible"
                style={{
                  userSelect: "none",
                }}
              />
              <InputWithClipBoard
                label="Your Referral Link"
                type="text"
                value={`${window.location.origin}/register?sponsorid=${data?.data?.user_id}`}
                disabled={true}
                inputGroupClass="right"
                copyToClipboard={true}
                visibility="visible"
                style={{
                  userSelect: "none",
                }}
              />
            </div>
          </div>
        </div>
      </CardLayout>
    </div>
  );
};

export default MyProfile;
