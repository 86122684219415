import React, { useState, useEffect } from "react";
// import CustomLink from "../../../../components/Link";
const HomepageFeatures = () => {
  const [trxData, setTrxData] = useState({});
  const trxFetch = async () => {
    await fetch(new Request("https://api.livecoinwatch.com/coins/single"), {
      method: "POST",
      headers: new Headers({
        "content-type": "application/json",
        "x-api-key": "89445443-2dc9-4779-ba5c-7b9a2f30d8cb",
      }),
      body: JSON.stringify({
        currency: "USD",
        code: "TRX",
        meta: true,
      }),
    })
      .then((res) => res.json())
      .then((data) => setTrxData(data));
  };
  useEffect(() => {
    trxFetch();
  }, []);
  return (
    <div className="Home_page_Features_wrapper">
      <div className="landingPage_container container">
        <div className="Home_page_Features_content">
          <div className="table_Features_card">
            <div className="table_Features crypto_card">
              <div className="crypto_details">
                <div className="crypto_name">
                  <img
                    src={trxData?.png64}
                    className="crypto_img"
                    alt="tron_img"
                  />
                  <div className="crypto_name_symbol">
                    <h2>{trxData?.name}</h2>
                    <p>{trxData?.name}/USD</p>
                  </div>
                </div>
                <div className="crypto_rate">
                  <h2>${trxData?.rate?.toFixed(8)}</h2>
                </div>
                <div className="crypto_market">
                  <div className="market">
                    <span>Market Cap Rank</span>
                    <span>#{trxData?.rank}</span>
                  </div>
                  <div className="market">
                    <span>Market Cap</span>
                    <span>${trxData?.cap}</span>
                  </div>
                  <div className="market">
                    <span>24H Volume</span>
                    <span>${trxData?.volume}</span>
                  </div>
                </div>
              </div>
            </div>
            <Card
              title={"TRON"}
              content={
                "The TRON DAO was established in July 2017 in Singapore.  TRON had launched its open source protocol. The Testnet, Blockchain Explorer, and Web Wallet were all launched by March 2018."
              }
            />
            <Card
              title={"Wallet"}
              content={
                "An address or wallet consisting of account credentials on the TRON network are generated by a key pair, which consists of a private key and a public key, the latter being derived from the former through an algorithm."
              }
            />
            <Card
              title={"Architecture"}
              content={
                "TRON adopts a 3-layer architecture divided into Storage Layer, Core Layer, and Application Layer. The TRON protocol adheres to Google Protobuf, which intrinsically supports multi-language extension"
              }
            />
            <Card
              title={"Transactions"}
              content={
                "TRON is a scalable blockchain solution that has employed innovative methods for tackling challenges faced by legacy blockchain networks. Having reached over 2M transactions per day."
              }
            />
            <Card
              title={"Application"}
              content={
                "Developers can create a diverse range of DApps and customized wallets on TRON. Since TRON enables smart contracts to be deployed and executed, the opportunities of utility applications are unlimited."
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomepageFeatures;

const Card = ({ title, content }) => {
  return (
    <div className="table_Features">
      <h1>{title}</h1>
      <p>{content}</p>
    </div>
  );
};
