import React, { useEffect, useState, useRef } from "react";
import { BsArrowLeftRight } from "react-icons/bs";
import CardLayout from "../../../components/CardLayout";
import SectionCommonTable from "../../../components/SectionCommonTable";
import HomeCard from "./Home.Card";
import Chart from "../../../components/Chart";
import { Notification } from "../../../components/ToastNotification";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import roiIcon from "../../../assets/dashboardIcon/roi.png";
import levelIcon from "../../../assets/dashboardIcon/level.png";
import teamIcon from "../../../assets/dashboardIcon/team.png";
import userListIcon from "../../../assets/dashboardIcon/userlist.png";
import {
  useGetLoginUserQuery,
  useLevelTeamListQuery,
} from "../../../Services/userApi";
import {
  useGetAllWalletQuery,
  useTransferHistoryQuery,
} from "../../../Services/walletApi";
import TeamTopup from "./table/TeamTopup";
import RoiInocmeTable from "./table/RoiIncomeTable";
import { popupShow } from "../../../containers/AuthPages/Login";
import Modal from "../../../components/Modal";
import { useClickOutside } from "../../../hooks/useClickOutside";
import ScreenShot from "../../../components/ScreensShot/ScreenShot";
import { useGetPopupImageQuery } from "../../../Services/Setting";

const HomePage = () => {
  const { data: popupImage } = useGetPopupImageQuery();
  /* all data fetching api */
  const { data } = useGetAllWalletQuery();
  /* fund transfer history */
  // const { data: transferHistoryShow } = useTransferHistoryQuery();
  // console.log(transferHistoryShow);
  // get user data
  const { data: userData } = useGetLoginUserQuery();

  // get fund transfer history data
  const { data: TransferHistoryData } = useTransferHistoryQuery();
  // console.log(TransferHistoryData);

  const { data: user_info } = useLevelTeamListQuery();
  const directTeamUsers = user_info?.level?.filter((team) => team?.level === 1);

  const [openModalForImage, setOpenModalForImage] = useState(popupShow);
  const modalImageRef = useRef(null);
  useClickOutside(modalImageRef, () => setOpenModalForImage(false));

  const [inputVal, setInputVal] = useState({
    leftVal: `${window.location.origin}/register?sponsorid=${userData?.data?.user_id}`,
  });
  const copyToClipboard = (type) => {
    if (type === "left") {
      navigator.clipboard.writeText(inputVal.leftVal);
      Notification("Text copied", "success");
    }
  };
  useEffect(() => {
    setInputVal({
      leftVal: `${window.location.origin}/register?sponsorid=${userData?.data?.user_id}`,
    });
  }, [userData?.data?.user_id]);
  return (
    <div className="tronFlow_homPage_wrapper" id="pddfff">
      <div className="tronFlow_section_title for_download_handle">
        <h2>Dashboard</h2>
      </div>
      {/* 1st row */}
      <ScreenShot width={600} height={900} pageName={"user-Dashboard"} />
      <div className="first_row dashboard_content">
        <div className="tronFlow_dash_content_item">
          <div className="tronFlow_dash_content card_row">
            <HomeCard
              cardName="Total Team"
              cardValue={user_info?.level?.length}
              icon={teamIcon}
              linkText="view details"
              bgColor="#38cab3"
            />
            <HomeCard
              cardName="ROI Income"
              cardValue={`$${
                data?.roi_bonus ? parseFloat(data?.roi_bonus).toFixed(3) : "0"
              }`}
              icon={roiIcon}
              linkText="view details"
              bgColor="#ffbd5a"
            />
            <HomeCard
              cardName="Level Income"
              cardValue={`$${
                data?.level_income
                  ? parseFloat(data?.level_income).toFixed(3)
                  : "0"
              }`}
              icon={levelIcon}
              bgColor="#f74f75"
            />
            <HomeCard
              cardName="Total Direct Team"
              cardValue={directTeamUsers?.length}
              icon={userListIcon}
              bgColor="#4ec2f0"
            />
          </div>
          <CardLayout
            style={{ backgroundColor: "#fff", overflow: "hidden" }}
            className="tronFlow_dashboard_chart"
          >
            <Chart />
          </CardLayout>
          <div className="tronFlow_link_copy_section card_row">
            <CardLayout>
              <form>
                <div className="tronFlow_input_field">
                  <Input
                    type="text"
                    placeholder="Enter refer link"
                    name="left_link"
                    value={inputVal.leftVal}
                    disabled={true}
                  />
                  <Button type="button" onClick={() => copyToClipboard("left")}>
                    copy refer link
                  </Button>
                </div>
              </form>
            </CardLayout>
          </div>
        </div>
      </div>
      {/* 2nd row */}
      <div className="second_row">
        <div className="recent_fund_transfer card_row">
          <CardLayout
            style={{ backgroundColor: "#fff" }}
            className="tronFlow_recent_fund_transfer_card"
          >
            <div className="tronFlow_recent_fund_transfer">
              <div className="card_heading">
                <h2>recent fund transfer</h2>
              </div>
              <div className="tronFlow_recent_fund_transfer_item">
                <ul className="tronFlow_recent_fund_transfer_ul">
                  {TransferHistoryData?.history?.slice(0, 4)?.map((d, i) => (
                    <li key={i}>
                      <div className="fund_transfer_image">
                        <span className="icon">
                          <BsArrowLeftRight />
                        </span>
                        <div className="left_name">
                          <p className="name">{d.receiver_id}</p>
                          <p className="date">
                            {new Date(d.date).toLocaleDateString()}
                          </p>
                        </div>
                      </div>
                      <div className="fund_transfer_content">
                        <p className="status">{d.status}</p>
                        <p className="amount">${d.amount}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </CardLayout>
        </div>
        <SectionCommonTable
          wrapperClassName="tronFlow_topup_card"
          cardStyle={{ backgroundColor: "#fff" }}
          sectionTableTitle="Team Topup"
          // table={<TeamTopupTable />}
          table={<TeamTopup />}
        />
      </div>
      {/* ROI Table */}
      <div className="tronFlow_my_topup card_row">
        <SectionCommonTable
          wrapperClassName="tronFlow_my_topup_card"
          cardStyle={{ backgroundColor: "#fff" }}
          sectionTableTitle="ROI Income"
          table={<RoiInocmeTable />}
        />
      </div>
      <Modal
        openModal={openModalForImage}
        setOpenModal={setOpenModalForImage}
        modalTitle=""
        modalRef={modalImageRef}
      >
        <div className="tronFlow_commol_modal_field">
          <img src={popupImage?.avatar} className="popupImage" alt="popup" />
        </div>
      </Modal>
    </div>
  );
};

export default HomePage;
