import {
  AiOutlineDashboard,
  AiOutlineToTop,
  AiOutlineWallet,
} from "react-icons/ai";
import { BiTransfer } from "react-icons/bi";
import { FiUser,FiSettings } from "react-icons/fi";
import { GiReceiveMoney, GiTakeMyMoney } from "react-icons/gi";
import { MdOutlineSupportAgent } from "react-icons/md";
import { RiTeamLine,RiMoneyDollarCircleLine } from "react-icons/ri";
export const menus = [
  // ************************************* common dashboard menu ************************//
  {
    id: "337fwedkf",
    menu: "dashboard",
    icon: <AiOutlineDashboard />,
    route: "/dashboard",
    permission: ["user","admin"],
  },
  // ************************************* admin dashboard menu ************************//
  {
    id: "7dfsegrr8srf",
    menu: "Member",
    icon: <FiUser />,
    permission: ["admin"],
    dropdown: [
      {
        id: "83agr89r4ifd",
        menu: "All Member",
        route: "/dashboard/all-member",
        permission: ["admin"],
      },
      {
        id: "838dga9r4ifd",
        menu: "Active Member",
        route: "/dashboard/active-member",
        permission: ["admin"],
      },
      {
        id: "838dga9ifd",
        menu: "Blocked Member",
        route: "/dashboard/blocked-member",
        permission: ["admin"],
      },
    ],
  },
  {
    id: "7dfsrs8srf",
    menu: "Deposit",
    icon: <GiTakeMyMoney />,
    permission: ["admin"],
    dropdown: [
      {
        id: "83bhi9r4ifd",
        menu: "All Deposit",
        route: "/dashboard/all-deposit",
        permission: ["admin"],
      },
      {
        id: "838dsoifd",
        menu: "Successful Deposit",
        route: "/dashboard/successful-deposit",
        permission: ["admin"],
      },
      {
        id: "8shbga9ifd",
        menu: "Rejected Deposit",
        route: "/dashboard/rejected-deposit",
        permission: ["admin"],
      },
    ],
  },
  {
    id: "ajjdrs8srf",
    menu: "withdraw",
    icon: <AiOutlineWallet />,
    permission: ["admin"],
    dropdown: [
      {
        id: "dajei9r4ifd",
        menu: "All Withdraw",
        route: "/dashboard/all-withdraw",
        permission: ["admin"],
      },
      {
        id: "ajobeoifd",
        menu: "Successful Withdraw",
        route: "/dashboard/successful-withdraw",
        permission: ["admin"],
      },
      {
        id: "aubia9ifd",
        menu: "Rejected Withdraw",
        route: "/dashboard/rejected-withdraw",
        permission: ["admin"],
      },
    ],
  },
  {
    id: "ajrgrs8rrf",
    menu: "Fund Transfer",
    icon: <BiTransfer />,
    permission: ["admin"],
    dropdown: [
      {
        id: "dajbaksjbd",
        menu: "All Report",
        route: "/dashboard/all-report",
        permission: ["admin"],
      },
    ],
  },
  {
    id: "audafebad",
    menu: "Earning",
    icon: <GiReceiveMoney />,
    permission: ["admin"],
    dropdown: [
      {
        id: "4fs8e77r8er",
        menu: "User Activation",
        route: "/dashboard/user-activation",
        permission: ["admin"],
      },
      {
        id: "aobeebnab",
        menu: "Level Income",
        route: "/dashboard/level-income",
        permission: ["admin"],
      },
      {
        id: "93gtq297b",
        menu: "ROI Income",
        route: "/dashboard/roi-income",
        permission: ["admin"],
      },
      {
        id: "f9q72b8a9",
        menu: "Reward Income",
        route: "/dashboard/reward-income",
        permission: ["admin"],
      },
    ],
  },
  {
    id: "5s48se8fe",
    menu: "Account",
    icon: <MdOutlineSupportAgent />,
    permission: ["admin"],
    dropdown: [
      {
        id: "f4s8fe8r8",
        menu: "Change Password",
        route: "/dashboard/change-password",
        permission: ["admin"],
      },
      {
        id: "fses7fe8r",
        menu: "Change Email",
        route: "/dashboard/change-email",
        permission: ["admin"],
      },
    ],
  },
  {
    id: "nfauawg43",
    menu: "Support",
    icon: <MdOutlineSupportAgent />,
    permission: ["admin"],
    dropdown: [
      {
        id: "naiuh3h33x",
        menu: "Create News",
        route: "/dashboard/create-news",
        permission: ["admin"],
      },
      {
        id: "98ay9uva",
        menu: "Contact Us",
        route: "/dashboard/contact-us",
        permission: ["admin"],
      },
      {
        id: "30ht9fwa",
        menu: "Ticket Data",
        route: "/dashboard/ticket-data",
        permission: ["admin"],
      },
    ],
  },
  {
    id: "sf4s8f7ser",
    menu: "Setting",
    icon: <FiSettings />,
    permission: ["admin"],
    dropdown: [
      {
        id: "sf4se8r9w",
        menu: "Popup Image",
        route: "/dashboard/popup-image",
        permission: ["admin"],
      },
    ],
  },
  // ************************************* user dashboard menu ************************//
  {
    id: "7dfser8srf",
    menu: "profile",
    icon: <FiUser />,
    permission: ["user"],
    dropdown: [
      {
        id: "8389r4ifd",
        menu: "my profile",
        route: "/dashboard/profile/my-profile",
        permission: ["user"],
      },
      {
        id: "f7d8e8sfrw",
        menu: "edit profile",
        route: "/dashboard/profile/edit-profile",
        permission: ["user"],
      },
      {
        id: "sf7s8f5s8er",
        menu: "change password",
        route: "/dashboard/profile/change-password",
        permission: ["user"],
      },
      {
        id: "f7s5f8se8r",
        menu: "change TRX password",
        route: "/dashboard/profile/changetrx-password",
        permission: ["user"],
      },
      {
        id: "sfs4f8se8",
        menu: "change email",
        route: "/dashboard/profile/change-email",
        permission: ["user"],
      },
      {
        id: "d7f8wer8s",
        menu: "edit wallet",
        route: "/dashboard/profile/edit-wallet",
        permission: ["user"],
      },
    ],
  },
  {
    id: "f7df8sfd7f",
    menu: "my wallet",
    icon: <AiOutlineWallet />,
    permission: ["user"],
    dropdown: [
      {
        id: "7854s8dfs5d",
        menu: "wallet",
        route: "/dashboard/wallet/my-wallet",
        permission: ["user"],
      },
      {
        id: "fd7d8s48574",
        menu: "deposit history",
        route: "/dashboard/wallet/deposit-history",
        permission: ["user"],
      },
      {
        id: "fsf7s7e8rr",
        menu: "transfer fund",
        route: "/dashboard/wallet/transfer-fund",
        permission: ["user"],
      },
      {
        id: "sfs7er8s8er",
        menu: "transfer history",
        route: "/dashboard/wallet/transfer-history",
        permission: ["user"],
      },
    ],
  },
  {
    id: "8s8dfdepser",
    menu: "deposit fund",
    icon: <RiMoneyDollarCircleLine />,
    route: "/dashboard/deposit-fund",
    permission: ["user"],
  },
  {
    id: "df79er8suf",
    menu: "team",
    icon: <RiTeamLine />,
    permission: ["user"],
    dropdown: [
      {
        id: "r3er8re8sdf7dn",
        menu: "direct team",
        route: "/dashboard/team/direct-team",
        permission: ["user"],
      },
      {
        id: "fs7erw55s",
        menu: "level team",
        route: "/dashboard/team/level-team",
        permission: ["user"],
      },
    ],
  },
  {
    id: "fsfsfusf8se4r",
    menu: "earning",
    icon: <GiTakeMyMoney />,
    permission: ["user"],
    dropdown: [
      {
        id: "e7rfs5fsenbv",
        menu: "level income",
        route: "/dashboard/earnings/level-income",
        permission: ["user"],
      },
      {
        id: "f7fbcbc4f5d",
        menu: "ROI income",
        route: "/dashboard/earnings/roi-income",
        permission: ["user"],
      },
      {
        id: "f7erfserp",
        menu: "reward income",
        route: "/dashboard/earnings/reward-income",
        permission: ["user"],
      },
    ],
  },
  {
    id: "mvkofsfosf",
    menu: "top up",
    icon: <AiOutlineToTop />,
    permission: ["user"],
    dropdown: [
      {
        id: "fndfhsorf",
        menu: "topup account",
        route: "/dashboard/topup/topup-account",
        permission: ["user"],
      },
      {
        id: "vnzsdajiodf8",
        menu: "topup history",
        route: "/dashboard/topup/topup-history",
        permission: ["user"],
      },
    ],
  },
  {
    id: "bvsrews7sf",
    menu: "withdraw",
    icon: <BiTransfer />,
    permission: ["user"],
    dropdown: [
      {
        id: "vvmkufisf",
        menu: "withdraw funds",
        route: "/dashboard/withdraw/withdraw-fund",
        permission: ["user"],
      },
      {
        id: "vnsff7s7f7d",
        menu: "withdraw history",
        route: "/dashboard/withdraw/withdraw-history",
        permission: ["user"],
      },
    ],
  },
  {
    id: "vnesurusf7",
    menu: "support",
    icon: <MdOutlineSupportAgent />,
    permission: ["user"],
    dropdown: [
      {
        id: "sfs4f4s8e8r",
        menu: "updates",
        route: "/dashboard/support/updates",
        permission: ["user"],
      },
      {
        id: "vmjdfsf8sf",
        menu: "support ticket",
        route: "/dashboard/support/support-ticket",
        permission: ["user"],
      },
      {
        id: "vmjderusf7s7",
        menu: "ticket history",
        route: "/dashboard/support/ticket-history",
        permission: ["user"],
      },
      {
        id: "f7s8f8fd",
        menu: "Contact Us",
        route: "/dashboard/support/contact-us",
        permission: ["user"],
      },
      {
        id: "f7s8f8fd",
        menu: "ContactUs History",
        route: "/dashboard/support/contact-us_history",
        permission: ["user"],
      },
    ],
  },
];
