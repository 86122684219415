import React from "react";
// import { GiReceiveMoney } from "react-icons/gi";
// import { HiOutlineUserGroup } from "react-icons/hi";
import CardLayout from "../../../components/CardLayout";
import SectionCommonTable from "../../../components/SectionCommonTable";
import UserIncomeCard from "../../../components/UserIncomeCard/UserIncomeCard";
// import HomeCard from "../HomePage/Home.Card";
import ROIIncomeTable from "./Table/roiIncomeTable";
import roiIncomeIcon from "../../../assets/dashboardIcon/roi.png";
import { useGetAllWalletQuery } from "../../../Services/walletApi";
import { useRoiIncomeDataUserQuery } from "../../../Services/earningApi";
import RoiIncomeBarchart from "./BarChart/RoiIncomeBarchart";

import ScreenShot from "../../../components/ScreensShot/ScreenShot";
const ROIIncomePage = () => {
  const { data } = useGetAllWalletQuery();
  const { data: roiIncome } = useRoiIncomeDataUserQuery();
  const amount = parseFloat(data?.roi_bonus).toFixed(3);

  return (
    <>
      <div className="UserEarning_wallet_page_wrapper">
        <div className="UserEarning_dash_content card_row">
          <ScreenShot width={600} height={410} pageName={"roi-Income-status"} />
          <UserIncomeCard
            cardName="ROI Income"
            cardValue={`$${amount ? amount : "0"}`}
            icon={roiIncomeIcon}
            bgColor="#38cab3"
            linkText="view details"
          />
          <CardLayout
            style={{ backgroundColor: "#fff", overflow: "hidden" }}
            className="tronFlow_dashboard_chart"
          >
            <RoiIncomeBarchart data={roiIncome} />
          </CardLayout>
        </div>
        <SectionCommonTable
          wrapperClassName="roi_table"
          cardStyle={{ backgroundColor: "#fff" }}
          sectionTableTitle="ROI Income"
          table={<ROIIncomeTable data={roiIncome} />}
        />
      </div>
    </>
  );
};

export default ROIIncomePage;
