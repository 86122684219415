import React, { useEffect, useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
// import CardLayout from "../../components/CardLayout";
import Input from "../../components/Input";
import CustomLink from "../../components/Link";
import SocialIconeforLogin from "../../components/SideBarSocialIcon/SocialIconeforLogin";
import SocialIconForCardHearder from "../../components/SideBarSocialIcon/SocialIconForCardHearder";
import { Notification } from "../../components/ToastNotification";
import { loginValidate } from "../../components/Validation/vaildate";
import { useAddLoginMutation, useAddOtpMutation } from "../../Services/userApi";
import {
  getLocalStorage,
  removeLocalStorage,
  savedLocalStorage,
} from "../../utils/function/localStorage";
import Footer from "../FrontPage/components/Footer";
import Header from "../FrontPage/components/Header";
import Particle from "../FrontPage/components/Particle";
import AuthCardLayout from "./AuthCardLayout";
export let popupShow = false;
const Login = () => {
  const [errors, setErrors] = useState({}); // error catch
  const navigate = useNavigate();
  const [value, setValue] = useState({
    user_id: "",
    password: "",
    otpCode: "",
  });
  const [OTPup, setOTPup] = useState(false);
  const handleChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  // auth check
  useEffect(() => {
    if (getLocalStorage("tron_token")) {
      navigate("/dashboard");
    }
  }, [navigate]);

  // error
  useEffect(() => {
    setErrors(loginValidate(value));
  }, [value]);

  // add user
  const [addLogin, { error, data, isLoading }] = useAddLoginMutation();
  useEffect(() => {
    if (data?.message) {
      Notification(data?.message, "success");
      navigate("/dashboard");
      popupShow = true;
      savedLocalStorage("tron_token", data?.token);
      removeLocalStorage("otp_timer");
      setTimeout(() => {
        popupShow = false;
      }, 3000);
    } else {
      Notification(error?.data?.message, "error");
    }
  }, [error, data, navigate]);
  // auto logout
  // autoLogout("token", 86400)

  // send otp code
  const [addOtp, { error: otpError, data: otpData }] = useAddOtpMutation();
  useEffect(() => {
    if (otpData?.message) {
      Notification(otpData?.message, "success");
      setOTPup(true);
    } else {
      Notification(otpError?.data?.message, "error");
      setOTPup(false);
    }
  }, [otpError, otpData]);

  // useEffect(() => {
  //   if (JSON.parse(getLocalStorage("otp_timer"))) {
  //     setTimeout(() => {
  //       console.log(JSON.parse(getLocalStorage("otp_timer")));
  //     }, parseInt(JSON.parse(getLocalStorage("otp_timer"))));
  //   }
  // }, []);
  if (JSON.parse(getLocalStorage("otp_timer"))) {
    setTimeout(() => {
      // console.log(JSON.parse(getLocalStorage("otp_timer")));
    }, parseInt(JSON.parse(getLocalStorage("otp_timer"))) * 1000);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (Object.keys(errors).length > 0) {
      Notification(errors.password, "error");
    } else {
      if (!value.otpCode) {
        const otp = {
          user_id: value.user_id.toUpperCase(),
          password: value.password,
        };
        await addOtp(otp);
        savedLocalStorage("otp_timer", 5000);
      } else {
        const logData = {
          ...value,
          user_id: value.user_id.toUpperCase(),
        };
        if (value.otpCode < 0) {
          Notification("Only Number Is Allowed On OTP", "error");
        } else {
          // console.log(logData);
          await addLogin(logData);
        }
      }
    }
  };
  const OTP_resend = async () => {
    const otp = {
      user_id: value.user_id.toUpperCase(),
      password: value.password,
    };
    await addOtp(otp);
  };
  // console.log(otpError);
  const [showPassword, setShowPassword] = useState(false);
  // redirect
  // const navigate = useNavigate();
  const token = getLocalStorage("tron_token");
  // let location = useLocation();
  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token, navigate]);
  return (
    <>
      <Particle />
      <SocialIconeforLogin />
      <Header />
      <div className="tron_dashboard_login_page_wrapper">
        <AuthCardLayout
          style={{ backgroundColor: "rgb(0 0 0 / 17%)" }}
          className="tron_dashboard_login_card tronFlow_all_card"
        >
          <div className="tron_section_title">
            <h2>Login</h2>
          </div>
          <div className="hr_border"></div>
          <SocialIconForCardHearder />
          <div className="tron_dashboard_login_content">
            <form onSubmit={handleSubmit}>
              <div className="form_group" style={{ display: "inherit" }}>
                <Input
                  label="User ID"
                  type="text"
                  name="user_id"
                  placeholder="Enter your user ID"
                  onChange={handleChange}
                  value={value.user_id}
                  className="userid_input input_field"
                  inputGroupClass="right"
                />
              </div>
              <div className="form_group" style={{ display: "inherit" }}>
                <Input
                  label="Password"
                  type={`${showPassword ? "text" : "password"}`}
                  name="password"
                  placeholder="Enter your password"
                  onChange={handleChange}
                  value={value.password}
                  className="password_input input_field"
                  inputGroupClass="right"
                />
                <span
                  style={{ marginTop: "0px" }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                </span>
              </div>
              {OTPup && (
                <div className="form_group form_group_OTP">
                  <Input
                    label="OTP"
                    type="number"
                    name="otpCode"
                    placeholder="Enter OTP"
                    onChange={handleChange}
                    value={value.otpCode}
                    className="OTP_input_field input_field"
                    inputGroupClass="left"
                  />
                  <Button
                    type="button"
                    className="OTP_resend_btn"
                    onClick={() => OTP_resend()}
                  >
                    Resend OTP
                  </Button>
                </div>
              )}
              <Button
                type="submit"
                className="submit_btn"
                // disabled={OTPup}
              >
                {isLoading ? "Loading..." : "Login"}
              </Button>
              <div className="go_to_register">
                <p>
                  <CustomLink href="/" className="log_page_nav_link">
                    Home
                  </CustomLink>{" "}
                </p>
                <p className="login_nav_break_point"> | </p>
                <p>
                  <CustomLink href="/register" className="log_page_nav_link">
                    Register
                  </CustomLink>{" "}
                </p>
                <p className="login_nav_break_point"> | </p>
                <p>
                  <CustomLink
                    href="/forgotPassword"
                    className="log_page_nav_link"
                  >
                    Forget Password
                  </CustomLink>{" "}
                </p>
              </div>
            </form>
          </div>
        </AuthCardLayout>
      </div>
      <Footer />
    </>
  );
};

export default Login;
