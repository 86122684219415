import React from "react";
import ScreenShot from "../../../components/ScreensShot/ScreenShot";
import SectionCommonTable from "../../../components/SectionCommonTable";
import DepositHistoryTable from "./table/depositHistoryTable";

const DepositFundHistoryPage = () => {
  return (
    <>
      <ScreenShot width={600} height={440} pageName={"deposit-funds-status"} />
      <SectionCommonTable
        wrapperClassName="deposithistory_table"
        cardStyle={{ backgroundColor: "#fff" }}
        sectionTableTitle="Deposit History"
        table={<DepositHistoryTable />}
      />
    </>
  );
};

export default DepositFundHistoryPage;
