import React from "react";
import { useState } from "react";
import ScreenShot from "../../../components/ScreensShot/ScreenShot";
import SectionCommonTable from "../../../components/SectionCommonTable";
import { useCompletedDepositeHistoryQuery } from "../../../Services/depositeApi";
import SuccessDepositTable from "./table/successDepositTable";

const SuccessfulTransaction = () => {
  const { data } = useCompletedDepositeHistoryQuery();
  const [filterData, setFilterData] = useState([]);
  return (
    <>
      <ScreenShot
        width={600}
        height={400}
        pageName={"successful-deposit-status"}
      />
      <SectionCommonTable
        wrapperClassName="allmember_table"
        cardStyle={{ backgroundColor: "#fff" }}
        sectionTableTitle={`Success Deposit History (${data?.length})`}
        data={data}
        setFilterData={setFilterData}
        table={
          <SuccessDepositTable
            data={filterData.length > 0 ? filterData : data}
          />
        }
      />
    </>
  );
};

export default SuccessfulTransaction;
