import React from "react";
import { useState } from "react";
import ScreenShot from "../../../components/ScreensShot/ScreenShot";
import SectionCommonTable from "../../../components/SectionCommonTable";
import { useCompletedWithdrawHistoryQuery } from "../../../Services/withdrawApi";
import SuccessWithdrawTable from "./table/successWithdrawTable";

const SuccessfulWithdraw = () => {
  const { data } = useCompletedWithdrawHistoryQuery();
  const [filterData, setFilterData] = useState([]);
  return (
    <>
      <ScreenShot
        width={600}
        height={400}
        pageName={"successful-withdraw-status"}
      />
      <SectionCommonTable
        wrapperClassName="successwithdraw_table"
        cardStyle={{ backgroundColor: "#fff" }}
        sectionTableTitle={`Success Withdraw History (${data?.length})`}
        data={data}
        setFilterData={setFilterData}
        table={
          <SuccessWithdrawTable
            data={filterData.length > 0 ? filterData : data}
          />
        }
      />
    </>
  );
};

export default SuccessfulWithdraw;
