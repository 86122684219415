import React from "react";
import CardLayout from "../../../components/CardLayout";
import SectionCommonTable from "../../../components/SectionCommonTable";
import UserIncomeCard from "../../../components/UserIncomeCard/UserIncomeCard";
// import HomeCard from "../HomePage/Home.Card";
import LevelIncomeBarchart from "./BarChart/LevelIncomeBarchart";
import LevelIncomeTable from "./Table/levelIncomeTabel";
import levelIncomeIcon from "../../../assets/dashboardIcon/level.png";
import { useGetAllWalletQuery } from "../../../Services/walletApi";
import {
  useGetLevelIncomeDataUserChartQuery,
  useLevelIncomeDataUserQuery,
} from "../../../Services/earningApi";

import ScreenShot from "../../../components/ScreensShot/ScreenShot";
const LevelIncome = () => {
  const { data } = useGetAllWalletQuery();
  const { data: levelIncomeData } = useLevelIncomeDataUserQuery();
  const amount = parseFloat(data?.level_income).toFixed(3);
  // console.log(window.innerHeight);
  return (
    <>
      <div className="UserEarning_wallet_page_wrapper">
        <div className="UserEarning_dash_content card_row">
          <ScreenShot
            width={200}
            height={810}
            pageName={"Level-Income-status"}
          />
          <UserIncomeCard
            cardName="Level Income"
            cardValue={`$${amount ? amount : "0"}`}
            linkText="view details"
            icon={levelIncomeIcon}
            bgColor="#ffbd5a"
          />
          <CardLayout
            style={{ backgroundColor: "#fff", overflow: "hidden" }}
            className="tronFlow_dashboard_chart"
          >
            <LevelIncomeBarchart data={levelIncomeData} />
          </CardLayout>
        </div>
        <SectionCommonTable
          wrapperClassName="levelincome_table"
          cardStyle={{ backgroundColor: "#fff" }}
          sectionTableTitle="Level Income"
          table={<LevelIncomeTable data={levelIncomeData} />}
        />
      </div>
    </>
  );
};

export default LevelIncome;
