import React from "react";
import { useState } from "react";
import ScreenShot from "../../../components/ScreensShot/ScreenShot";
import SectionCommonTable from "../../../components/SectionCommonTable";
import { useCanceledWithdrawHistoryQuery } from "../../../Services/withdrawApi";
import RejectWithdrawTable from "./table/rejectWithdrawTable";

const RejectedWithdraw = () => {
  const { data } = useCanceledWithdrawHistoryQuery();
  const [filterData, setFilterData] = useState([]);
  return (
    <>
      <ScreenShot
        width={600}
        height={400}
        pageName={"all-rejected-withdraw-status"}
      />
      <SectionCommonTable
        wrapperClassName="rejectedwithdraw_table"
        cardStyle={{ backgroundColor: "#fff" }}
        sectionTableTitle={`Rejected Withdraw History (${data?.length})`}
        data={data}
        setFilterData={setFilterData}
        table={
          <RejectWithdrawTable
            data={filterData.length > 0 ? filterData : data}
          />
        }
      />
    </>
  );
};

export default RejectedWithdraw;
