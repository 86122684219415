import React from "react";
import ScreenShot from "../../../components/ScreensShot/ScreenShot";
import SectionCommonTable from "../../../components/SectionCommonTable";
import DirectTable from "./table/directTable";

const DirectTeamPage = () => {
  return (
    <>
      <ScreenShot width={600} height={400} pageName={"direct-team-status"} />
      <SectionCommonTable
        wrapperClassName="directteam_table"
        cardStyle={{ backgroundColor: "#fff" }}
        sectionTableTitle="My Direct Team"
        table={<DirectTable />}
      />
    </>
  );
};

export default DirectTeamPage;
