import React from "react";
import DataTable from "../../../../components/DataTable";

const RewardIncomeTable = ({data}) => {

  const columns = [
    { id: "sn", label: "S.N", minWidth: 20 },
    { id: "userid", label: "User ID", minWidth: 100 },
    {
      id: "date",
      label: "Date",
      minWidth: 120,
    },
    {
      id: "direct_activation",
      label: "Direct Activation",
      minWidth: 110,
    },
    {
      id: "total_activation",
      label: "Total Activation",
      minWidth: 110,
    },
    {
      id: "reward",
      label: "Reward",
      minWidth: 80,
    },
    {
      id: "transaction_id",
      label: "Transaction ID",
      minWidth: 100,
    },
  ];

  function createData(
    sn,
    userid,
    date,
    direct_activation,
    total_activation,
    reward,
    transaction_id
  ) {
    return {
      sn,
      userid,
      date,
      direct_activation,
      total_activation,
      reward,
      transaction_id,
    };
  }

  const rows = data?.reward_income?.map((d, i) =>
    createData(
      i + 1,
      d.user_id,
      new Date(d.date).toDateString(),
      "$" + parseFloat(d?.direct_activation).toFixed(3),
      "$" + parseFloat(d?.total_activation).toFixed(3),
      "$" +parseFloat(d?.reward).toFixed(3),
      d.transaction_id
    )
  );
  // console.log(data);
  return (
    <DataTable
      columns={columns}
      rows={rows}
      perPageShow={6}
      tableHeight={440}
      className="common_table"
    />
  );
};

export default RewardIncomeTable;
