import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { env } from "../env";
import { getLocalStorage } from "../utils/function/localStorage";

export const earningApi = createApi({
  reducerPath: "earningApi",
  baseQuery: fetchBaseQuery({
    baseUrl: env.BASE_URL,
    // mode: 'cors',
    prepareHeaders: (headers) => {
      headers.set("authorization", getLocalStorage("tron_token"));
      return headers;
    },
  }),
  tagTypes: ["adminEarning", "user"], // automatic-data fetching
  endpoints: (builder) => ({
    // admin
    roiIncomeData: builder.query({
      query: () => "/private/api/roi_income_data",
      providesTags: ["adminEarning"], // automatic-data fetching
    }),
    levelIncomeData: builder.query({
      query: () => "/private/api/level_income_data",
      providesTags: ["adminEarning"], // automatic-data fetching
    }),
    rewardIncomeData: builder.query({
      query: () => "/private/api/reward_income_data",
      providesTags: ["adminEarning"], // automatic-data fetching
    }),

    // user earning
    roiIncomeDataUser: builder.query({
      // query: (user_id) => `/secure/api/user/get_investment/${user_id}`,
      query: () => `/secure/api/get_investment`,
      providesTags: ["user"], // automatic-data fetching
    }),
    levelIncomeDataUser: builder.query({
      // query: (user_id) => `/secure/api/user/level_income/${user_id}`,
      query: () => `/secure/api/level_income`,
      providesTags: ["user"], // automatic-data fetching
    }),
    rewardIncomeDataUser: builder.query({
      // query: (user_id) => `/secure/api/user/reward_income/${user_id}`,
      query: () => `/secure/api/reward_income`,
      providesTags: ["user"], // automatic-data fetching
    }),
    // graphchart data
    getRoiIncomeDataUserChart: builder.query({
      // query: (user_id) => `/secure/api/user/get_investment/${user_id}`,
      query: () => `/secure/api/roi_income_chart`,
      providesTags: ["user"], // automatic-data fetching
    }),
    getLevelIncomeDataUserChart: builder.query({
      // query: (user_id) => `/secure/api/user/level_income/${user_id}`,
      query: () => `/secure/api/level_income_chart`,
      providesTags: ["user"], // automatic-data fetching
    }),
    getRewardIncomeDataUserChart: builder.query({
      // query: (user_id) => `/secure/api/user/reward_income/${user_id}`,
      query: () => `/secure/api/reward_income_chart`,
      providesTags: ["user"], // automatic-data fetching
    }),
  }),
});

export const {
  useRoiIncomeDataQuery,
  useLevelIncomeDataQuery,
  useRoiIncomeDataUserQuery,
  useLevelIncomeDataUserQuery,
  useRewardIncomeDataUserQuery,
  useRewardIncomeDataQuery,
  useGetLevelIncomeDataUserChartQuery,
  useGetRewardIncomeDataUserChartQuery,
  useGetRoiIncomeDataUserChartQuery
} = earningApi;
