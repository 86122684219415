import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { env } from "../env";
import { getLocalStorage } from "../utils/function/localStorage";

export const walletApi = createApi({
  reducerPath: "walletApi",
  baseQuery: fetchBaseQuery({
    baseUrl: env.BASE_URL,
    // mode: 'cors',
    prepareHeaders: (headers) => {
      headers.set("authorization", getLocalStorage("tron_token"));
      return headers;
    },
  }),
  tagTypes: ["userWallet"], // automatic-data fetching
  endpoints: (builder) => ({
    // user
    getAllWallet: builder.query({
      query: () => `/secure/api/get_wallet`,
      providesTags: ["userWallet"], // automatic-data fetching
    }),
    depositHistory: builder.query({
      query: () => `/secure/api/deposite_history`,
      providesTags: ["userWallet"], // automatic-data fetching
    }),
    transferHistory: builder.query({
      query: () => `/secure/api/transfer_fund_history`,
      providesTags: ["userWallet"], // automatic-data fetching
    }),
    transferFund: builder.mutation({
      query: (body) => ({
        url: "/secure/api/transfer_fund",
        method: "POST",
        body,
      }),
      invalidatesTags: ["userWallet"], // automatic-data fetching
    }),
    addDepositFund: builder.mutation({
      query: (body) => ({
        url: "/secure/api/deposite",
        method: "POST",
        body,
      }),
      invalidatesTags: ["userWallet"], // automatic-data fetching
    }),
    getDepositHistory: builder.query({
      query: () => "/secure/api/deposite_history",
      providesTags: ["userWallet"], // automatic-data fetching
    }),
    topupHistory: builder.query({
      query: () => `/secure/api/topup_history`,
      providesTags: ["userWallet"], // automatic-data fetching
    }),
    topupApiPackage: builder.mutation({
      query: (body) => ({
        url: "/secure/api/make_topup",
        method: "POST",
        body,
      }),
      invalidatesTags: ["userWallet"], // automatic-data fetching
    }),
    createROI: builder.mutation({
      query: (body) => ({
        url: "/secure/api/create_investment",
        method: "POST",
        body,
      }),
      invalidatesTags: ["userWallet"], // automatic-data fetching
    }),
    getWithdrawHistory: builder.query({
      query: () => `/secure/api/withdraw_history`,
      providesTags: ["userWallet"], // automatic-data fetching
    }),
    addWithdrawFunds: builder.mutation({
      // user register
      query: (body) => ({
        url: "/secure/api/withdraw",
        method: "POST",
        body,
      }),
      invalidatesTags: ["userWallet"], // automatic-data fetching
    }),
  }),
});

export const {
  useGetWithdrawHistoryQuery,
  useAddWithdrawFundsMutation,
  useTopupHistoryQuery,
  useTopupApiPackageMutation,
  useAddDepositFundMutation,
  useGetDepositHistoryQuery,
  useGetAllWalletQuery,
  useDepositHistoryQuery,
  useTransferHistoryQuery,
  useTransferFundMutation,
  useCreateROIMutation
} = walletApi;
