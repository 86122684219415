import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../containers/AuthPages/Login";
import Register from "../containers/AuthPages/Register";
import ForgotPassword from "../containers/AuthPages/ForgotPassword";
import Dashboard from "./dashboard";
import FrontPage from "./frontPage";
import ResetPassword from "../containers/AuthPages/ResetPassword";
import TermsConditions from "../containers/AuthPages/TermsConditions";
import {
  removeLocalStorage,
} from "../utils/function/localStorage";

const Routers = () => {
    window.onunload = (e) => {
      e.preventDefault();
      const pageAccessedByReload =
        (window.performance.navigation &&
          window.performance.navigation.type === 1) ||
        window.performance
          .getEntriesByType("navigation")
          .map((nav) => nav.type)
          .includes("reload");

      if (pageAccessedByReload) {
        return;
      }
      removeLocalStorage("tron_token");
    };
  return (
    <>
      {/* <BrowserRouter> */}
      <Routes>
        <Route path="/*" index element={<FrontPage />} />
        <Route path="/dashboard/*" element={<Dashboard />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/resetpassword/:token" element={<ResetPassword />} />
        <Route path="/termsconditions" element={<TermsConditions />} />
      </Routes>
      {/* </BrowserRouter> */}
    </>
  );
};

export default Routers;
