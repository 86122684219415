import React, { useRef, useState } from "react";
import Modal from "../../../components/Modal";
import ScreenShot from "../../../components/ScreensShot/ScreenShot";
import SectionCommonTable from "../../../components/SectionCommonTable";
import { useClickOutside } from "../../../hooks/useClickOutside";
import { useRoiIncomeDataQuery } from "../../../Services/earningApi";
import RoiTable from "./table/roiTable";

const ROIIncomeEarning = () => {
  // get all roi income
  const { data } = useRoiIncomeDataQuery();
  const [details, setDetails] = useState({});
  const showDetails = (body) => {
    setDetails(body);
    setOpenModal(true);
  };
  // modal toggle
  const [openModal, setOpenModal] = useState(false);
  const modalRef = useRef(null);
  useClickOutside(modalRef, () => setOpenModal(false));
  const [filterData, setFilterData] = useState([]);
  return (
    <>
      <ScreenShot width={600} height={400} pageName={"roi-Income-status"} />
      <SectionCommonTable
        wrapperClassName="allroiincome_table"
        cardStyle={{ backgroundColor: "#fff" }}
        sectionTableTitle={`All ROI Income (${data?.length})`}
        data={data}
        setFilterData={setFilterData}
        table={
          <RoiTable
            showDetails={showDetails}
            data={filterData.length > 0 ? filterData : data}
          />
        }
      />
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        modalTitle="Transaction Details"
        modalRef={modalRef}
      >
        <div className="tronFlow_commol_modal_field">
          <div className="transaction_details">
            <div className="group">
              <p>
                <strong>Package:</strong> <span>{details.packages}</span>
              </p>
              <p>
                <strong>Receiver User Id:</strong>{" "}
                <span>{details.user_id}</span>
              </p>
              <p>
                <strong>Transfer Via:</strong>{" "}
                <span
                  style={{
                    backgroundColor: "rgba(255,189,90,.2)",
                    color: "#ffc107",
                  }}
                >
                  Admin
                </span>
              </p>
            </div>
            <div className="group">
              <p>
                <strong>Date:</strong> <span>{details.day}</span>
              </p>
              <p>
                <strong>Time:</strong> <span>{details.time}&nbsp;(IST)</span>
              </p>
            </div>
            <div className="group">
              <p>
                <strong>Amount:</strong> <span>${details.amount}</span>
              </p>
              <p>
                <strong>Status:</strong>{" "}
                <span
                  style={{
                    textTransform: "capitalize",
                    backgroundColor: "rgba(28,213,174,.2)",
                    color: "#38cab3",
                  }}
                >
                  success
                </span>
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ROIIncomeEarning;
