import React from "react";
import { FaFacebookF } from "react-icons/fa";
import {
  AiOutlineTwitter,
  AiOutlineInstagram,
  AiFillYoutube,
  AiFillLinkedin,
} from "react-icons/ai";
import { RiTelegramLine, RiPinterestFill } from "react-icons/ri";

// import Button from "../../../../components/Button";
// import CustomLink from "../../../../components/Link";
const Footer = () => {
  return (
    <div className="tron_front_footer_wrapper">
      <div className="container">
        <footer className="footer-container">
          <p style={{ color: "#fff !important" }}>
            Copyright © 2022-2040, Powered By{" "}
            <a href="https://TronFlow/">TronFlow</a>{" "}
          </p>
          <div className="content">
            <ul className="social_link">
              <li>
                <a
                  className="footer_menu"
                  rel="noreferrer"
                  href="https://www.facebook.com/Tronliveclub-101421886064301/"
                  target="_blank"
                >
                  <FaFacebookF className="facebook" />{" "}
                </a>
              </li>
              <li>
                <a
                  className="footer_menu"
                  rel="noreferrer"
                  href="https://twitter.com/TronLive_club/"
                  target="_blank"
                >
                  <AiOutlineTwitter className="twitter" />{" "}
                </a>
              </li>
              <li>
                <a
                  className="footer_menu"
                  rel="noreferrer"
                  href="https://www.instagram.com/TronFlow/"
                  target="_blank"
                >
                  <AiOutlineInstagram className="instagram" />{" "}
                </a>
              </li>
              <li>
                <a
                  className="footer_menu"
                  rel="noreferrer"
                  href="https://www.linkedin.com/in/tronlive-club-b0802824b/"
                  target="_blank"
                >
                  <AiFillLinkedin className="linkedin" />{" "}
                </a>
              </li>
              <li>
                <a
                  className="footer_menu"
                  rel="noreferrer"
                  href="https://t.me/TronFlow"
                  target="_blank"
                >
                  <RiTelegramLine className="telegram" />{" "}
                </a>
              </li>
              <li>
                <a
                  className="footer_menu"
                  rel="noreferrer"
                  href="https://in.pinterest.com/tron_flow/"
                  target="_blank"
                >
                  <RiPinterestFill className="interest" />{" "}
                </a>
              </li>
              <li>
                <a
                  className="footer_menu"
                  rel="noreferrer"
                  href="https://www.youtube.com/channel/UCdYBY943bvbneHMnNzezAtQ"
                  target="_blank"
                >
                  <AiFillYoutube className="youtube" />{" "}
                </a>
              </li>
            </ul>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Footer;
