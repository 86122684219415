import React, { useState } from "react";
import { Link } from "react-scroll";
import { FaBars } from "react-icons/fa";
import tronClubDrakLogo from "../../../../assets/tronClubIconDark.png";

import CustomLink from "../../../../components/Link";
import {
  getLocalStorage,
  removeLocalStorage,
} from "../../../../utils/function/localStorage";
const Header = ({ hidePackageRoute }) => {
  const [stickyNav, setStickyNav] = useState(false);
  const stickyNavbar = () => {
    if (window.pageYOffset > 100) {
      setStickyNav(true);
      return;
    } else {
      setStickyNav(false);
      return;
    }
  };
  const handleLogout = () => {
    removeLocalStorage("tron_token");
  };
  window.addEventListener("scroll", stickyNavbar);
  const [showToggleNav, setShowToggleNav] = useState(false);
  return (
    <div
      className={`tron_frontpage_header_wrapper ${stickyNav ? "sticky" : ""}`}
    >
      <div className="container">
        <div className="tron_front_navbar">
          <div className="toggler_icon">
            <FaBars onClick={() => setShowToggleNav(!showToggleNav)} />
          </div>
          <div className="tron_front_logo">
            <CustomLink href="/" className="logo">
              <img src={tronClubDrakLogo} alt="logo" />
            </CustomLink>
            <ul className="tron_front_navbar_lists2">
              {getLocalStorage("tron_token") ? (
                <li className="tron_front_navbar_list2 ">
                  <CustomLink
                    href="/dashboard"
                    className="tron_front_nav_link2 tron_front_navbar_dashboard"
                  >
                    Dashboard
                  </CustomLink>
                </li>
              ) : (
                <li className="tron_front_navbar_list2">
                  <CustomLink
                    href="/login"
                    className="tron_front_nav_link2 login2"
                  >
                    Login
                  </CustomLink>
                </li>
              )}
              {getLocalStorage("tron_token") ? (
                <li className="tron_front_navbar_list2" onClick={handleLogout}>
                  <CustomLink
                    href="/login"
                    className="tron_front_nav_link2 tron_front_navbar_logOut"
                  >
                    Logout
                  </CustomLink>
                </li>
              ) : (
                <li className="tron_front_navbar_list2">
                  <CustomLink
                    href="/register"
                    className="tron_front_nav_link2 register2"
                  >
                    Register
                  </CustomLink>
                </li>
              )}
            </ul>
          </div>
          <div
            className={`tron_front_navbar_menu ${
              showToggleNav ? "toggle_navbar" : ""
            }`}
          >
            <ul className="tron_front_navbar_lists">
              <li className="tron_front_navbar_list">
                <CustomLink href="/" className="tron_front_nav_link">
                  Home
                </CustomLink>
              </li>
              <li className="tron_front_navbar_list">
                {hidePackageRoute ? (
                  <Link
                    to="packages"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                    className="tron_front_nav_link"
                  >
                    Package
                  </Link>
                ) : (
                  <CustomLink href="/packages" className="tron_front_nav_link">
                    Package
                  </CustomLink>
                )}
              </li>

              <li className="tron_front_navbar_list">
                <CustomLink href="/contact" className="tron_front_nav_link">
                  Contact US
                </CustomLink>
              </li>
              <li className="tron_front_navbar_list">
                <CustomLink href="#" className="tron_front_nav_link">
                  Coming soon
                </CustomLink>
                <div className="sub-menu-l">
                  <ul>
                    <li>
                      <CustomLink href="#">Pre ICO</CustomLink>
                    </li>
                    <li>
                      <CustomLink href="#">Robot Trade</CustomLink>
                    </li>
                    <li>
                      <CustomLink href="#">Self exchange</CustomLink>
                    </li>
                    <li>
                      <CustomLink href="#">Banking | ATM </CustomLink>
                    </li>
                    <li>
                      <CustomLink href="#">Metaverse</CustomLink>
                    </li>
                    <li>
                      <CustomLink href="#">E-commerce</CustomLink>
                    </li>
                    <li>
                      <CustomLink href="#">Blockchain</CustomLink>
                    </li>
                    <li>
                      <CustomLink href="#">Gaming</CustomLink>
                    </li>
                    <li>
                      <CustomLink href="#">Utility Services</CustomLink>
                    </li>
                    {/* <li>Pre ICO</li>
                    <li>Robot Trade</li>
                    <li>Self exchange</li>
                    <li>Metaverse</li>
                    <li>Self exchange</li> */}
                  </ul>
                </div>
              </li>
              {getLocalStorage("tron_token") ? (
                <li className="tron_front_navbar_list register">
                  <CustomLink href="/dashboard" className="tron_front_nav_link">
                    Dashboard
                  </CustomLink>
                </li>
              ) : (
                <li className="tron_front_navbar_list login">
                  <CustomLink href="/login" className="tron_front_nav_link">
                    Login
                  </CustomLink>
                </li>
              )}
              {getLocalStorage("tron_token") ? (
                <li
                  className="tron_front_navbar_list login"
                  onClick={handleLogout}
                >
                  <CustomLink href="/login" className="tron_front_nav_link">
                    Logout
                  </CustomLink>
                </li>
              ) : (
                <li className="tron_front_navbar_list register">
                  <CustomLink href="/register" className="tron_front_nav_link">
                    Register
                  </CustomLink>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
