import React from "react";
import SectionCommonTable from "../../../components/SectionCommonTable";
import LevelTeam from "./table/levelTeam";

const LevelTeamHistory = () => {
  return (
    <SectionCommonTable
      wrapperClassName="levelteam_table"
      cardStyle={{ backgroundColor: "#fff" }}
      sectionTableTitle="Level Team"
      table={<LevelTeam />}
    />
  );
};

export default LevelTeamHistory;
