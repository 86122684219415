import React from "react";
import DataTable from "../../../../components/DataTable";

const columns = [
  { id: "sn", label: "S.N", minWidth: 20 },
  { id: "userid", label: "User ID", minWidth: 100 },
  {
    id: "date",
    label: "Date",
    minWidth: 120,
  },
  {
    id: "reward",
    label: "Reward",
    minWidth: 80,
  },
  {
    id: "transaction_id",
    label: "Transaction ID",
    minWidth: 100,
  },
];

const RewardTable = ({ showDetails, data }) => {
  function createData(
    sn,
    userid,
    date,
    reward,
    transaction_id
  ) {
    return {
      sn,
      userid,
      date,
      reward,
      transaction_id,
    };
  }

  const rows = data?.map((d) =>
    createData(
      d?.id,
      d?.user_id,
      new Date(d?.date).toDateString(),
      "$"+ parseInt(d?.reward)?.toFixed(3),
      <span
        onClick={() => showDetails(d)}
        style={{
          userSelect: "none",
          cursor: "pointer",
          textDecoration: "underline",
        }}
      >
        {d.transaction_id}
      </span>
    )
  );
  return (
    <DataTable
      columns={columns}
      rows={rows}
      perPageShow={6}
      tableHeight={440}
      className="common_table"
    />
  );
};

export default RewardTable;
