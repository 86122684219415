import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import {
  AllTransaction,
  RejectedTransaction,
  SuccessfulTransaction,
} from "../pages/AdminPages/depositReport";
import {
  LevelIncomeEarning,
  ROIIncomeEarning,
  RewardIncomeEarning,
} from "../pages/AdminPages/earningTrack";
import { AllReport } from "../pages/AdminPages/fundTransfer";
// import { AdminHome} from "../pages/AdminPages/home"
import {
  ActiveMember,
  AllMember,
  BlockedMember,
} from "../pages/AdminPages/memberPage";
import { ContactUs, TicketDataAdmin } from "../pages/AdminPages/support";
import {
  AllWithdraw,
  RejectedWithdraw,
  SuccessfulWithdraw,
} from "../pages/AdminPages/withdrawReport";

import { UpdateEmail, UpdateTrxPassword } from "../containers/UserPages/index";

// import { AdminHome } from "../pages/AdminPages/AdminDashboardHome";
import {
  LevelIncome,
  RewardIncomePage,
  RoiIincome,
} from "../pages/UserPages/earning";
// import { HomePage } from "../pages/UserPages/UserDashboardHome";
import { DirectTeamPage, LevelTeamHistory } from "../pages/UserPages/myTeam";
import {
  ContactPage,
  SupportTicketPage,
  TicketHistoryPage,
  UpdateNewsPage,
  ContactusHistory,
} from "../pages/UserPages/support";
import { AccountTopupPage, TopupHistoryPage } from "../pages/UserPages/topup";
import {
  MyProfile,
  UpdatePassword,
  UpdateProfile,
  UpdateWallet,
} from "../pages/UserPages/userProfile";
import {
  DepositFundHistoryPage,
  DepositFundPage,
  FundTransferHistoryPage,
  FundTransferPage,
  MyWallet,
} from "../pages/UserPages/wallet";
import { WithdrawHistoryPage, WithdrawPage } from "../pages/UserPages/withdraw";
import {
  getLocalStorage
} from "../utils/function/localStorage";
import UserActivation from "../containers/AdminPages/AdminEarningTrack/Admin.UserActivation";
import ChangePassword from "../containers/AdminPages/Admin.Account/Admin.ChangePassword";
import ChangeEmail from "../containers/AdminPages/Admin.Account/Admin.ChangeEmail";
import { ForAdminPrivateRoute, ForUserPrivateRoute } from "./ProtectedRoute";
import { userRole } from "../config/USER_ROLE";
import DashboardHomePage from "../containers/DashboardHomePage/DashboardHomePage";
import CreateNews from "../containers/AdminPages/Admin.Support/CreateNews";
import PopupImagePage from "../containers/AdminPages/Admin.Setting/PopupImage";
// import { isTokenExpired } from "../config/tokenValidate";
// import { isTokenExpired } from "../config/tokenValidate";
// import * as jwt_decode from 'jwt-decode';

const AppContent = () => {
  const navigate = useNavigate();
  const token = getLocalStorage("tron_token");
  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [navigate, token]);
  return (
    <Routes>
      <Route path="/" index element={<DashboardHomePage />} exact />
      {/************************************* for admin *****************************************/}
      <Route element={<ForAdminPrivateRoute roles={[userRole.ADMIN]} />}>
        {/* <Route path="/" index element={<AdminHome />} exact /> */}
        <Route path="/all-member" element={<AllMember />} />
        <Route path="/active-member" element={<ActiveMember />} />
        <Route path="/blocked-member" element={<BlockedMember />} />
        <Route path="/all-deposit" element={<AllTransaction />} />
        <Route path="/successful-deposit" element={<SuccessfulTransaction />} />
        <Route path="/rejected-deposit" element={<RejectedTransaction />} />
        <Route path="/all-withdraw" element={<AllWithdraw />} />
        <Route path="/successful-withdraw" element={<SuccessfulWithdraw />} />
        <Route path="/rejected-withdraw" element={<RejectedWithdraw />} />
        <Route path="/all-report" element={<AllReport />} />
        <Route path="/user-activation" element={<UserActivation />} />
        <Route path="/level-income" element={<LevelIncomeEarning />} />
        <Route path="/roi-income" element={<ROIIncomeEarning />} />
        <Route path="/reward-income" element={<RewardIncomeEarning />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/change-email" element={<ChangeEmail />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/create-news" element={<CreateNews />} />
        <Route path="/ticket-data" element={<TicketDataAdmin />} />
        <Route path="/popup-image" element={<PopupImagePage />} />
      </Route>

      {/********************************** user dashboard *************************************/}
      <Route element={<ForUserPrivateRoute roles={[userRole.USER]} />}>
        {/* <Route path="/" index element={<HomePage />} exact/> */}
        <Route path="/profile/my-profile" element={<MyProfile />} />
        <Route path="/profile/edit-profile" element={<UpdateProfile />} />
        <Route path="/profile/edit-wallet" element={<UpdateWallet />} />
        <Route path="/profile/change-password" element={<UpdatePassword />} />
        <Route
          path="/profile/changetrx-password"
          element={<UpdateTrxPassword />}
        />
        <Route path="/profile/change-email" element={<UpdateEmail />} />
        <Route path="/team/direct-team" element={<DirectTeamPage />} />
        <Route path="/team/level-team" element={<LevelTeamHistory />} />
        <Route path="/wallet/my-wallet" element={<MyWallet />} />
        <Route path="/deposit-fund" element={<DepositFundPage />} />
        <Route
          path="/wallet/deposit-history"
          element={<DepositFundHistoryPage />}
        />
        <Route path="/wallet/transfer-fund" element={<FundTransferPage />} />
        <Route
          path="/wallet/transfer-history"
          element={<FundTransferHistoryPage />}
        />
        <Route path="/earnings/level-income" element={<LevelIncome />} />
        <Route path="/earnings/roi-income" element={<RoiIincome />} />
        <Route path="/earnings/reward-income" element={<RewardIncomePage />} />
        <Route path="/topup/topup-account" element={<AccountTopupPage />} />
        <Route path="/topup/topup-history" element={<TopupHistoryPage />} />
        <Route path="/withdraw/withdraw-fund" element={<WithdrawPage />} />
        <Route
          path="/withdraw/withdraw-history"
          element={<WithdrawHistoryPage />}
        />
        <Route path="/support/updates" element={<UpdateNewsPage />} />
        <Route path="/support/support-ticket" element={<SupportTicketPage />} />
        <Route path="/support/contact-us" element={<ContactPage />} />
        <Route path="/support/ticket-history" element={<TicketHistoryPage />} />
        <Route
          path="/support/contact-us_history"
          element={<ContactusHistory />}
        />
      </Route>
    </Routes>
  );
};

export default AppContent;
