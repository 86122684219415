import React from "react";
import { userRole } from "../../config/USER_ROLE";
import { useGetLoginUserQuery } from "../../Services/userApi";
import AdminHome from "../AdminPages/AdminHome/AdminHome";
import HomePage from "../UserPages/HomePage/index";

const DashboardHomePage = () => {
  const { data } = useGetLoginUserQuery();
  return (
    <>
      {data?.data?.role === userRole.USER && <HomePage />}
      {data?.data?.role === userRole.ADMIN && <AdminHome />}
    </>
  );
};

export default DashboardHomePage;
