import React from "react";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillYoutube,
  AiOutlineTwitter,
} from "react-icons/ai";

const SocialIconForCardHearder = () => {
  return (
    <div className="login_page_social_icon">
      <a
        href="https://twitter.com/TronLive_club"
        target="_blank"
        rel="noreferrer"
      >
        <AiOutlineTwitter className="social_icon twitter" />
      </a>
      <a
        href="https://www.facebook.com/Tronliveclub-101421886064301/"
        target="_blank"
        rel="noreferrer"
      >
        <AiFillFacebook className="social_icon facebook" />
      </a>
      <a
        href="https://www.instagram.com/TronFlow/"
        target="_blank"
        rel="noreferrer"
      >
        <AiFillInstagram className="social_icon instagram" />
      </a>
      <a
        href="https://www.youtube.com/channel/UCdYBY943bvbneHMnNzezAtQ"
        target="_blank"
        rel="noreferrer"
      >
        <AiFillYoutube className="social_icon youtube" />
      </a>
    </div>
  );
};

export default SocialIconForCardHearder;
