import React from "react";
import DataTable from "../../../../components/DataTable";

const columns = [
  { id: "sn", label: "S.N", minWidth: 20 },
  { id: "userid", label: "User ID", minWidth: 100 },
  { id: "packages", label: "Package", minWidth: 100 },
  {
    id: "date",
    label: "Date",
    minWidth: 120,
  },
  {
    id: "roi_per_day",
    label: "ROI Per Day",
    minWidth: 110,
  },
  {
    id: "amount",
    label: "Amount",
    minWidth: 80,
  },
  {
    id: "transaction_id",
    label: "Transaction ID",
    minWidth: 100,
  },
];

const RoiTable = ({ showDetails, data }) => {
  function createData(
    sn,
    userid,
    packages,
    date,
    roi_per_day,
    amount,
    transaction_id
  ) {
    return {
      sn,
      userid,
      packages,
      date,
      roi_per_day,
      amount,
      transaction_id,
    };
  }

  const rows = data?.map((d, index) =>
    createData(
      index + 1,
      d?.user_id,
      "$" + d?.packages,
      new Date(d?.date).toDateString(),
      d?.roi_per_day + "%",
      "$" + d?.amount,
      // "$" + d?.total_amount?.toFixed(3),
      <span
        onClick={() => showDetails(d)}
        style={{
          userSelect: "none",
          cursor: "pointer",
          textDecoration: "underline",
        }}
      >
        {d.transaction_id}
      </span>
    )
  );
  return (
    <DataTable
      columns={columns}
      rows={rows}
      perPageShow={6}
      tableHeight={440}
      className="common_table"
    />
  );
};

export default RoiTable;
