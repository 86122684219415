import React from "react";
import HomeCard from "../HomePage/Home.Card";
import roiIncomeIcon from "../../../assets/dashboardIcon/roi.png";
import rewardIncomeIcon from "../../../assets/dashboardIcon/reward.png";
import levelIncomeIcon from "../../../assets/dashboardIcon/level.png";
import totalIncomeIcon from "../../../assets/dashboardIcon/income.png";
import depositFundIncomeIcon from "../../../assets/dashboardIcon/invest.png";
import withdrawIcon from "../../../assets/dashboardIcon/withdraw-money.png";
import { useGetAllWalletQuery } from "../../../Services/walletApi";
import ScreenShot from "../../../components/ScreensShot/ScreenShot";

const Wallet = () => {
  const { data } = useGetAllWalletQuery();
  return (
    <div className="wallet_page_wrapper">
      <ScreenShot width={600} height={380} pageName={"wallet-status"} />
      <div className="tronFlow_dash_content card_row">
        <HomeCard
          cardName="ROI Income"
          cardValue={`$${
            data?.roi_bonus ? parseFloat(data?.roi_bonus).toFixed(3) : "0"
          }`}
          icon={roiIncomeIcon}
          bgColor="#38cab3"
          linkText="view details"
        />
        <HomeCard
          cardName="Level Income"
          cardValue={`$${
            data?.level_income ? parseFloat(data?.level_income).toFixed(3) : "0"
          }`}
          linkText="view details"
          icon={levelIncomeIcon}
          bgColor="#ffbd5a"
        />
        <HomeCard
          cardName="Reward Income"
          cardValue={`$${
            data?.reward_income
              ? parseFloat(data?.reward_income).toFixed(3)
              : "0"
          }`}
          linkText="view details"
          icon={rewardIncomeIcon}
          bgColor="#f74f75"
        />
        <HomeCard
          cardName="Total Income"
          cardValue={`$${
            data?.total_income ? parseFloat(data?.total_income).toFixed(3) : "0"
          }`}
          linkText="view details"
          icon={totalIncomeIcon}
          bgColor="#4ec2f0"
        />
        <HomeCard
          cardName="Deposit Fund"
          cardValue={`$${
            data?.total_deposite
              ? parseFloat(data?.total_deposite).toFixed(3)
              : "0"
          }`}
          linkText="view details"
          icon={depositFundIncomeIcon}
          bgColor="#18a7b5"
        />
        <HomeCard
          cardName="Total Withdraw"
          cardValue={`$${
            data?.total_withdraw
              ? parseFloat(data?.total_withdraw).toFixed(3)
              : "0"
          }`}
          linkText="view details"
          icon={withdrawIcon}
          bgColor="#39bd57"
        />
      </div>
    </div>
  );
};

export default Wallet;
