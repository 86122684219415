import React from "react";
const HomeCard = ({ cardName, cardValue, link, linkText, icon, bgColor }) => {
  return (
    <div className="tronFlow_dash_card_wrapper">
      <div className="tronFlow_content">
        <div
          className="tronFlow_widget_icon"
          style={{ backgroundColor: bgColor }}
        >
          <img src={icon} style={{ width: "35px", padding: "10px" }} alt="" />
        </div>
        <div className="tronFlow_widget_info">
          <p>{cardName}</p>
          <h2>{cardValue}</h2>
        </div>
      </div>
    </div>
  );
};

export default HomeCard;
