import React from "react";
import { useState } from "react";
const ConverterBox = () => {
  const [values, setValue] = useState({
    usd: "",
    trx: "",
  });
  // const trx = parseFloat(allWalletInfo?.total_deposite);
  const trx_amount = (parseInt(values.usd) * 17)?.toFixed(2);
  const usd_amount = (parseInt(values.trx) / 17)?.toFixed(2);

  return (
    <div className="converter_field">
      <h2>convert currency</h2>
      <div className="converter_input_field">
        <div className="usd_field input">
          <input
            type="number"
            name="usd"
            value={values.usd}
            onChange={(e) => setValue({ ...values, usd: e.target.value })}
            placeholder="USD to TRX"
          />
          <strong className="trx_amount_card">TRX&nbsp;{trx_amount > 0 ? trx_amount : "00.00"}</strong>
          <span>USD</span>
        </div>
        <div className="trx_field input">
          <input
            type="number"
            name="trx"
            value={values.trx}
            onChange={(e) => setValue({ ...values, trx: e.target.value })}
            placeholder="TRX to USD"
          />
          <strong className="usd_amount_card">USD&nbsp;${usd_amount > 0 ? usd_amount : "00.00"}</strong>

          <span className="trx_amount_card">TRX</span>
        </div>
      </div>
    </div>
  );
};

export default ConverterBox;
