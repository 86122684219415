import React from "react";

const Footer = () => {
  const now = new Date().getFullYear();
  return (
    <footer style={{ padding: "20px 0", textAlign: "center" }}>
      <p className="footer-text">
        Copyright © Prowered By{" "}
        <a href="https://TronFlow/" target="_blank" rel="noreferrer">
          TronFlow
        </a>{" "}
        {now}
      </p>
    </footer>
  );
};

export default Footer;
